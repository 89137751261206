<template>
  <div class="surface">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Surface',
  });
</script>

<style lang="scss" scoped>
.surface {
  background: rgb(var(--color-surface));
  border: 1px solid rgb(var(--color-secondary-border));
  box-shadow: 0px 1px rgb(var(--color-secondary-border));
  border-radius: 2rem;
  padding: 3rem 2.5rem;
}
</style>
