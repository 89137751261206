<template>
  <div class="label">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Label',
  });
</script>

<style lang="scss" scoped>
.label {
  font-size: .875em;
  display: inline-block;
  border: 2px solid rgb(var(--color-secondary-border));
  color: rgb(var(--color-text-secondary));
  border-radius: .375em;
  padding: .25em .375em;
  margin-left: .5em;
  line-height: 1;
  vertical-align: middle;
}
</style>
