<template>
  <section class="profile">
    <!-- Lesson generator -->
    <Surface class="lesson-generator">
      <h2 class="headline-1 title">
        {{ t('pages.profile.lessonGenerator.title') }}
      </h2>
      <p
        class="paragraph subtitle"
        type="secondary"
      >
        {{ t('pages.profile.lessonGenerator.subtitle') }}
      </p>
      <div class="generator-options">
        <div class="generator-option">
          <Checkbox
            v-model="generatorOptions.letters"
            class="checkbox"
            :label="t(`pages.profile.lessonGenerator.options[0]`)"
          />
        </div>
        <div class="generator-option">
          <Checkbox
            v-model="generatorOptions.numbers"
            class="checkbox"
            :label="t(`pages.profile.lessonGenerator.options[1]`)"
          />
        </div>
        <div class="generator-option">
          <Checkbox
            v-model="generatorOptions.symbols"
            class="checkbox"
            :label="t(`pages.profile.lessonGenerator.options[2]`)"
          />
        </div>
      </div>
      <Button
        ref="launch"
        type="primary"
        keybind="Space"
        class="action"
        :disabled="!generatorOptions.letters && !generatorOptions.numbers && !generatorOptions.symbols"
        @click="router.push({ name: 'trainer'})"
      >
        {{ t('pages.profile.lessonGenerator.actionButton') }}
      </Button>
    </Surface>

    <!-- Keyboard layout -->
    <Surface class="keyboard-layout">
      <h2 class="headline-1 title">
        {{ t('pages.profile.keyboardLayout.title') }}
      </h2>
      <p
        class="paragraph subtitle"
        type="secondary"
      >
        <span>{{ t(`keyboardLayouts.${layoutProps.language}.${layoutProps.name}`) }}</span>
        <Label>{{ t(`layoutFormats.${layoutProps.format}`) }}</Label>
      </p>

      <router-link
        :to="{name: 'layout-setup'}"
        class="action"
      >
        {{ t('pages.profile.keyboardLayout.actionButton') }}
      </router-link>
      <Keyboard
        :layout-props="layoutProps"
        :handle-typing="true"
        class="keyboard"
      />
    </Surface>

    <!-- Color theme -->
    <Surface class="theme-selector">
      <h2 class="headline-1 title">
        {{ t('pages.profile.themeSelector.title') }}
      </h2>
      <div class="options">
        <div
          class="option"
          :class="{'active': selectedTheme === 'light'}"
          @click="selectTheme('light')"
        >
          <div class="illustration-wrapper">
            <img
              class="illustration"
              src="@/assets/img/theme-icons/light.svg"
              draggable="false"
              alt="light-theme"
            >
          </div>
          <div class="label">
            {{ t('pages.profile.themeSelector.light') }}
          </div>
        </div>
        <div
          class="option"
          :class="{'active': selectedTheme === 'dark'}"
          @click="selectTheme('dark')"
        >
          <div class="illustration-wrapper dark">
            <img
              class="illustration"
              src="@/assets/img/theme-icons/dark.svg"
              draggable="false"
              alt="dark-theme"
            >
          </div>
          <div class="label">
            {{ t('pages.profile.themeSelector.dark') }}
          </div>
        </div>
        <div
          class="option"
          :class="{'active': selectedTheme === 'system'}"
          @click="selectTheme('system')"
        >
          <div class="double-illustration">
            <div class="illustration-wrapper light">
              <img
                class="illustration"
                src="@/assets/img/theme-icons/light.svg"
                draggable="false"
                alt="light-theme"
              >
            </div>
            <div class="illustration-wrapper dark">
              <img
                class="illustration"
                src="@/assets/img/theme-icons/dark.svg"
                draggable="false"
                alt="dark-theme"
              >
            </div>
          </div>

          <div class="label">
            {{ t('pages.profile.themeSelector.system') }}
          </div>
        </div>
      </div>
    </Surface>

    <!-- Support -->
    <Surface class="share-feedback">
      <h2 class="headline-1 title">
        {{ t('pages.profile.supportUs.title') }}
      </h2>
      <p
        class="paragraph subtitle"
        type="secondary"
      >
        {{ t('pages.profile.supportUs.subtitle') }}
      </p>
      <div class="actions">
        <Button
          type="secondary"
          icon-path="emoji/bug.png"
          link="mailto:bugs@typing.school"
        >
          {{ t('pages.profile.supportUs.actions.reportBug') }}
        </Button>
        <Button
          type="secondary"
          icon-path="emoji/bulb.png"
          link="mailto:ideas@typing.school"
        >
          {{ t('pages.profile.supportUs.actions.suggestIdea') }}
        </Button>
      </div>
      <lottie-animation
        class="illustration"
        path="lottie/mailbox.json"
      />
    </Surface>
  </section>
</template>

<script lang="ts">
  import {
    ref, Ref, watchEffect, onMounted, onUnmounted, ComponentPublicInstance, defineComponent,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { codeProxy } from '@/utils';
  import { shortcutKeys, shortcutActions } from '@/components/key-shortcut/shortcuts';
  import { KeyCode, LayoutProps } from '@/config/keyboards/dataTypes';
  import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

  import Button from '@/components/Button.vue';
  import Checkbox from '@/components/Checkbox.vue';
  import Keyboard from '@/components/keyboard/Keyboard.vue';
  import Surface from '@/components/html-elements/Surface.vue';
  import Label from '@/components/html-elements/Label.vue';

  export default defineComponent({
    name: 'Profile',

    components: {
      Button,
      Checkbox,
      Keyboard,
      Surface,
      Label,
      LottieAnimation,
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' });
      const router = useRouter();
      const store = useStore();

      const savedTheme = localStorage.getItem('selectedTheme');
      const selectedTheme = ref(savedTheme || 'system');

      const layoutProps: Ref<LayoutProps> = ref(store.getters['layout/props']);

      type GeneratorOptions = {
        letters: boolean,
        numbers: boolean,
        symbols: boolean,
      };

      const generatorOptions: Ref<GeneratorOptions> = ref({
        letters: localStorage.getItem('generatorOptionsLetters') !== 'false',
        numbers: localStorage.getItem('generatorOptionsNumbers') !== 'false',
        symbols: localStorage.getItem('generatorOptionsSymbols') !== 'false',
      });

      watchEffect(() => {
        localStorage.setItem('generatorOptionsLetters', String(generatorOptions.value.letters));
        localStorage.setItem('generatorOptionsNumbers', String(generatorOptions.value.numbers));
        localStorage.setItem('generatorOptionsSymbols', String(generatorOptions.value.symbols));
      });

      const selectTheme = (theme: string) => {
        switch (theme) {
          case 'light':
            store.dispatch('theme/setLight');
            localStorage.setItem('selectedTheme', theme);
            break;
          case 'dark':
            store.dispatch('theme/setDark');
            localStorage.setItem('selectedTheme', theme);
            break;
          default:
            localStorage.removeItem('selectedTheme');
            store.dispatch('theme/init');
            break;
        }

        selectedTheme.value = theme;
      };

      // Keybinds

      const keybinds = {
        launch: {
          key: shortcutKeys.space,
          action: shortcutActions.launch,
        },
      };

      const launch: Ref<null | ComponentPublicInstance> = ref(null);

      const handleKeybind = (e: KeyboardEvent) => {
        const code = codeProxy(e.code as KeyCode<null>, window.navigator, layoutProps.value.format);

        if (code === keybinds.launch.key.code) {
          if (!generatorOptions.value.letters && !generatorOptions.value.numbers && !generatorOptions.value.symbols) {
            return;
          }

          e.preventDefault();

          router.push({ name: 'trainer' });

          // if (launch.value) {
          //   launch.value.$emit('click');
          // } else {
          //   // TODO process exception - when there is not launch element on page
          // }
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', handleKeybind);
      });

      onUnmounted(() => {
        document.removeEventListener('keydown', handleKeybind);
      });

      return {
        t,
        router,
        generatorOptions,
        layoutProps,
        selectTheme,
        selectedTheme,
        launch,
      };
    },
  });
</script>

<style lang="scss" scoped>
.profile {
  $gap: 2rem;

  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: $gap;
  padding-top: $gap;
  padding-bottom: $gap;

  .surface {
    display: grid;

    .title {
      grid-area: title;
      margin: 0;
      margin-bottom: .5rem;
    }

    .subtitle {
      grid-area: subtitle;
      margin: 0;
      margin-bottom: 1.5rem;
    }

    &.lesson-generator {
      grid: "title action" max-content "subtitle action" max-content "options action" max-content / 1fr min-content;

      .generator-options {
        grid-area: options;
        display: flex;

        .generator-option:not(:last-child) {
          margin-right: 1.5rem;
        }
      }

      .action {
        grid-area: action;
        align-self: center;
      }
    }

    &.keyboard-layout {
      grid: "title action" max-content "subtitle action" max-content "keyboard keyboard" max-content / 1fr max-content;

      .action {
        grid-area: action;
        justify-self: flex-end;
        align-self: flex-start;
        font-weight: 500;
        font-size: 1rem;
      }

      .keyboard {
        grid-area: keyboard;
      }

      .subtitle {
        color: rgb(var(--color-text-secondary));
        display: flex;
        align-items: center;
        margin-bottom: 2.5rem;
      }
    }

    &.theme-selector {
      grid: "title" max-content "options" max-content / 100%;

      .title {
        margin-bottom: 2.5rem;
      }

      .options {
        grid-area: options;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1.25rem;

        .option {
          border: 1px solid rgb(var(--color-secondary-border));
          border-radius: 1em;
          overflow: hidden;
          transition: all .3s ease-out;
          position: relative;

          .illustration-wrapper {
            padding: 1.625rem;
            background-color: #F1F1F1;
            display: flex;

            .illustration {
              width: 100%;
              height: 100%;
              border: 1px solid #E5E6EB;
              box-shadow: 0px 1px 1px 0 rgb(0 0 0 / .1);
              border-radius: 1em;
            }

            &.dark {
              background-color: #3F3F3F;

              .illustration {
                border-color: #3A3A3A;
              }
            }
          }

          .double-illustration {
            position: relative;

            .illustration-wrapper {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }

            .illustration-wrapper:first-child {
              clip-path: inset(0 50% 0 0);
            }

            .illustration-wrapper:last-child {
              clip-path: inset(0 0 0 50%);
            }
          }

          .double-illustration + .label {
            position: absolute;
            left: 0;
            bottom: 0;
          }

          .label {
            padding: .75rem 0 .75rem 2.125rem;
            line-height: 1;
            position: relative;
            font-size: 1rem;

            &:after {
              $size: 1rem;

              content: " ";
              display: block;
              position: absolute;
              top: 0;
              bottom: .0625rem;
              margin: auto;
              left: #{$size * .8};
              width: $size;
              height: $size;
              border: 1px solid rgb(var(--color-secondary-border-darker));
              border-radius: $size;
              transition: border .3s ease-out;
            }
          }

          &.active {
            border: 1px solid rgb(var(--color-secondary-border-darker));

            .label {
              font-weight: 500;

              &:after {
                box-shadow: inset 0 0 0 4px rgb(var(--color-primary));
                border: none;
              }
            }
          }

          &:hover {
            cursor: pointer;
            border-color: rgb(var(--color-secondary-border-darker));

            .label {
              &:after {
                border-color: rgb(var(--color-text-primary));
              }
            }
          }

          &:active {
            border-color: rgb(var(--color-text-secondary));
          }
        }
      }
    }

    &.share-feedback {
      grid:
        "title illustration" max-content
        "subtitle illustration" max-content
        "actions illustration" max-content
        / auto min-content;
      column-gap: 2rem;

      .subtitle {
        max-width: 32rem;
      }

      .actions {
        display: grid;
        grid-template-columns: max-content max-content max-content;
        align-self: center;
        grid-column-gap: .625rem;
      }

      .illustration {
        grid-area: illustration;
        justify-self: flex-end;
        align-self: center;
        overflow: hidden;
        width: 9rem !important;
        transform: scale(1.25);
      }
    }
  }
}
</style>
