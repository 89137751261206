// Shortcut Key

type ShortcutKey = {
  label: string, // exact text to show to user
  code: string // e.code
}

const shortcutKeys = {
  esc: {
    label: 'Esc',
    code: 'Escape',
  },
  space: {
    label: 'Space',
    code: 'Space',
  },
} as {[id: string]: ShortcutKey};

// Shortcut Action

const shortcutActions = {
  pause: 'pause',
  continue: 'continue',
  restart: 'restart',
  launch: 'launch',
} as const;

type ShortcutAction = typeof shortcutActions[keyof typeof shortcutActions];

// Shortcut

type Shortcut = {
  key: ShortcutKey,
  action: ShortcutAction
}

export {
  ShortcutKey, shortcutKeys, ShortcutAction, shortcutActions, Shortcut,
};
