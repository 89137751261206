
  import {
    ref, Ref, watchEffect, onMounted, onUnmounted, ComponentPublicInstance, defineComponent,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { codeProxy } from '@/utils';
  import { shortcutKeys, shortcutActions } from '@/components/key-shortcut/shortcuts';
  import { KeyCode, LayoutProps } from '@/config/keyboards/dataTypes';
  import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

  import Button from '@/components/Button.vue';
  import Checkbox from '@/components/Checkbox.vue';
  import Keyboard from '@/components/keyboard/Keyboard.vue';
  import Surface from '@/components/html-elements/Surface.vue';
  import Label from '@/components/html-elements/Label.vue';

  export default defineComponent({
    name: 'Profile',

    components: {
      Button,
      Checkbox,
      Keyboard,
      Surface,
      Label,
      LottieAnimation,
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' });
      const router = useRouter();
      const store = useStore();

      const savedTheme = localStorage.getItem('selectedTheme');
      const selectedTheme = ref(savedTheme || 'system');

      const layoutProps: Ref<LayoutProps> = ref(store.getters['layout/props']);

      type GeneratorOptions = {
        letters: boolean,
        numbers: boolean,
        symbols: boolean,
      };

      const generatorOptions: Ref<GeneratorOptions> = ref({
        letters: localStorage.getItem('generatorOptionsLetters') !== 'false',
        numbers: localStorage.getItem('generatorOptionsNumbers') !== 'false',
        symbols: localStorage.getItem('generatorOptionsSymbols') !== 'false',
      });

      watchEffect(() => {
        localStorage.setItem('generatorOptionsLetters', String(generatorOptions.value.letters));
        localStorage.setItem('generatorOptionsNumbers', String(generatorOptions.value.numbers));
        localStorage.setItem('generatorOptionsSymbols', String(generatorOptions.value.symbols));
      });

      const selectTheme = (theme: string) => {
        switch (theme) {
          case 'light':
            store.dispatch('theme/setLight');
            localStorage.setItem('selectedTheme', theme);
            break;
          case 'dark':
            store.dispatch('theme/setDark');
            localStorage.setItem('selectedTheme', theme);
            break;
          default:
            localStorage.removeItem('selectedTheme');
            store.dispatch('theme/init');
            break;
        }

        selectedTheme.value = theme;
      };

      // Keybinds

      const keybinds = {
        launch: {
          key: shortcutKeys.space,
          action: shortcutActions.launch,
        },
      };

      const launch: Ref<null | ComponentPublicInstance> = ref(null);

      const handleKeybind = (e: KeyboardEvent) => {
        const code = codeProxy(e.code as KeyCode<null>, window.navigator, layoutProps.value.format);

        if (code === keybinds.launch.key.code) {
          if (!generatorOptions.value.letters && !generatorOptions.value.numbers && !generatorOptions.value.symbols) {
            return;
          }

          e.preventDefault();

          router.push({ name: 'trainer' });

          // if (launch.value) {
          //   launch.value.$emit('click');
          // } else {
          //   // TODO process exception - when there is not launch element on page
          // }
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', handleKeybind);
      });

      onUnmounted(() => {
        document.removeEventListener('keydown', handleKeybind);
      });

      return {
        t,
        router,
        generatorOptions,
        layoutProps,
        selectTheme,
        selectedTheme,
        launch,
      };
    },
  });
